import { Component } from '@angular/core';
import { VudModalComponent } from '@vismaux/ngx-vud';

@Component({
  selector: 'di-employees-list-get-employees-error',
  templateUrl: './employees-list-get-employees-error.component.html',
  styleUrls: ['./employees-list-get-employees-error.component.scss'],
})
export class EmployeesListGetEmployeesErrorComponent extends VudModalComponent<void, boolean> {
  closeModal(value?: boolean): void {
    this.close(value);
  }
}
