<div class="modal-header">
  <h4 class="modal-title" i18n>Employee loading timed out</h4>
</div>
<div class="modal-body">
  <span i18n>
    There seems to be a high load on Employees service and it has been timed out. Please try again or contact Support if
    the problem persists.
  </span>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="button" (click)="closeModal(true)" i18n> Try again </button>
  <button class="btn" type="button" (click)="closeModal()" i18n>Cancel</button>
</div>
